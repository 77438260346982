<template>
<div class="plan-card-container">
    <div class="premium-tag-container">
        <div class="premium-tag">
            <div class="premium-content">
                <img class="crown mr-5" src="@/assets/icons/crown_jhakaas.svg" />
                <p class="premium">{{ $t(plan.planinterval + '_' + "plan") }}</p>
            </div>
        </div>
    </div>
    <div class="plan-card">
        <img class="plan-card-img" :src="plan.picture" v-if="plan && plan.picture" />
        <div class="plan-card-inner">
            <div>
                <div class="offer-class">
                        <img v-if="!getCancelButtonStatus && showOfferSticker" src="@/assets/icons/offersticker.svg" alt="limited period offer">
                         <img v-else-if="!getCancelButtonStatus && showRowSticker" src="@/assets/icons/bestvalue.svg" alt="best value">
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                     <p v-if="plan.currency !== 'USD'" :class="{ textCenter: getCancelButtonStatus }" :style="showOfferSticker ? 'background: linear-gradient(to right, #9b1e2d, #e81c25, #9b1e2d);' : ''" class="plan-name">
                    {{ $t(plan.planname) }}
                     </p>
                    </div>

            </div>
            <div class="plan-price" v-if="!getCancelButtonStatus">
                <span v-if="planOriginalPrice" class="stricksprice" v-html="currencyCode(plan)"></span>
                <span v-html="currencyCodeSubscriber(plan)"></span>
                <span> / {{$t(planInterval)}}</span>
      
            </div> 
            

            <div v-if="!getCancelButtonStatus" class="ads-free"> {{(planCardTxt)}}
            </div>
            <div v-if="!getCancelButtonStatus" class="d-flex flex-gap-20 align-items-between mt-16">
                <img src="@/assets/icons/check_jhakaas.png" class="check secondary-color" />
                <p class="promo-text secondary-color">{{$t("Access to over 3000+ hours of exclusive Marathi content")}}</p>

            </div>

            <div v-if="!getCancelButtonStatus" class="d-flex flex-gap-20 align-items-between mt-16">
                <img src="@/assets/icons/check_jhakaas.png" class="check secondary-color" />

                <p class="promo-text secondary-color">{{$t("HD quality content")}}</p>

            </div>

            <div v-if="!getCancelButtonStatus" class="d-flex flex-gap-20 align-items-between mt-16">
                <img src="@/assets/icons/check_jhakaas.png" class="check secondary-color" />

                <p class="promo-text secondary-color">{{$t("Watch on Mobile, TV, Laptop")}}</p>

            </div>
            <div v-if="!getCancelButtonStatus" class="d-flex flex-gap-20 align-items-between mt-16">
                <img src="@/assets/icons/check_jhakaas.png" class="check secondary-color" />

                <p class="promo-text secondary-color">{{$t("Ad-Free")}}</p>

            </div>
            <button v-if="(plan.buttonStatus === 'Subscribe Now' || getReactivateButtonStatus) && !showLoader" class="button-primary mt-5" @click="openPaymentModal(plan)">
                {{ $t(plan.buttonStatus) }}
            </button>
            <p v-if="getCancelButtonStatus" class="plan-price-subs secondary-color" style="margin-top: 10px; text-align: initial;" v-html="currencyCodeSubscriber(plan)"></p>
            <div class="plan-expire" v-if="plan.expiry">{{ getCancelButtonStatus ?  $t('Renewal Date') : $t('expires on') }} {{getFormatedDate(plan.expiry)}}
            </div>

            <div v-if="getCancelButtonStatus" class="d-flex flex-gap-20 align-items-between mt-16">
                <img src="@/assets/icons/check_jhakaas.png" class="check secondary-color" />
                <p class="promo-text secondary-color">{{$t("Access to over 3000+ hours of exclusive Marathi content")}}</p>

            </div>

            <div v-if="getCancelButtonStatus" class="d-flex flex-gap-20 align-items-between mt-16">
                <img src="@/assets/icons/check_jhakaas.png" class="check secondary-color" />

                <p class="promo-text secondary-color">{{$t("HD quality content")}}</p>

            </div>

            <div v-if="getCancelButtonStatus" class="d-flex flex-gap-20 align-items-between mt-16">
                <img src="@/assets/icons/check_jhakaas.png" class="check secondary-color" />

                <p class="promo-text secondary-color">{{$t("Watch on Mobile, TV, Laptop")}}</p>

            </div>

            <div v-if="getCancelButtonStatus" class="d-flex flex-gap-20 align-items-between mt-16">
                <img src="@/assets/icons/check_jhakaas.png" class="check secondary-color" />

                <p class="promo-text secondary-color">{{$t("Ad-Free")}}</p>

            </div>

            <div class="plan-cancel-container" v-if="getCancelButtonStatus && clientcancancel">

                <p class="plan-cancel" @click="planCancel(plan)">{{ $t("Cancel plan") }}</p>

            </div>

        </div>
    </div>
</div>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import {
    _providerId
} from "@/provider-config.js";
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    eventBus
} from "@/eventBus";
import "../../.././node_modules/node-snackbar/dist/snackbar.css";
import {
    store
} from "@/store/store";

import googleAnalytics from "./../../mixins/googleAnalytics";
import facebookPixelAnalytics from "@/mixins/facebookPixelAnalytics.js";
import {
    showSnackBar
} from '../../utilities';
export default {
    props: {
        plan: {
            type: Object,
        },
        gatewayList: {
            type: Array,
        },
    },
    data() {
        return {
            planPreviousPrice: 0,
            providerUniqueId: _providerId,
            localDisplayLang: null,
            telcoGateway: null,
            showPaymentLabels: false,
            operatorGateway: null,
            planTag: null,
            showLoader: false,
            mobileNumber: null,
            planAndDiscount: null,
            sessionToken: null,
            planCardTxt: '',
            planOriginalPrice: null,
            discountPercentage: 0,
            planInterval: 0,
            enumDataset : { 
                "DAY": "DAY",
                "WEEK": "WEEK",
                "MONTH": "MONTH",
                "QUARTER": "3 MONTHS",
                "HALFYEAR": "HALF YEAR",
                "YEAR": "YEAR"
            },
            offerPlanIds: [],
            rowPlanIds: [],
          
        };
    },
    computed: {
        
        ...mapGetters(["getRtl", "appConfig", "subscriberid"]),
        
        getCancelButtonStatus() {
            if (this.plan.subscriptionStatus === "ACTIVE") {
                return true;
            } else {
                return false;
            }
        },
        clientcancancel () {
            if (this.plan.clientcancancel == "NO") {
                return false;
            } else {
                return true;
            }

        },

        getReactivateButtonStatus() {
            if (
                this.plan.buttonStatus === "REACTIVATE" &&
                this.plan.subscriptionStatus === "CANCELLED" &&
                this.plan.clientcanreactivate === "YES"
            ) {
                return true;
            } else {
                return false;
            }
        },

        getIndirectCancellationGateways() {
            let gatewayExists = false;
            this.appConfig.indirectCancellationGateways.forEach((ele) => {
                if (ele.gatewayid === this.plan.gwprovider) {
                    gatewayExists = true;
                }
            });

            return gatewayExists;
        },
        showOfferSticker() {
            return this.offerPlanIds.includes(this.plan.planid);
        },
        showRowSticker() {
            return this.rowPlanIds.includes(this.plan.planid);
        },
        
    },
    created() {
        this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
        this.sessionToken = localStorage.getItem("sessionToken"),

            this.planAndDiscount = this.appConfig.planDiscount;

        let configPlans = this.appConfig.planPrice;
        for (let itemIndex = 0; itemIndex < configPlans.length; itemIndex++) {
            let planItem = configPlans[itemIndex];
            let originalPrice = parseInt(planItem.originalprice[0].price) / 100;
            let planid = planItem.planid;
            let payloadPlanId = this.plan.planid;
            if (planid === payloadPlanId) {
                this.planOriginalPrice = originalPrice;
                this.discountPercentage = planItem.discountOffer;
                break;
            }
        }

        //fetch plantag from config.
        this.planTag = this.appConfig.planTags[this.plan.planid] ? this.appConfig.planTags[this.plan.planid] : "";

        //fetch oreedo payment
        if (
            (this.plan.hasOwnProperty("isSTC") && this.plan.isSTC) ||
            (this.plan.hasOwnProperty("isOreedo") && this.plan.isOreedo) ||
            (this.plan.hasOwnProperty("isDUUAE") && this.plan.isDUUAE) ||
            (this.plan.hasOwnProperty("isZainKwt") && this.plan.isZainKwt) ||
            (this.plan.hasOwnProperty("isZainJod") && this.plan.isZainJod) ||
            (this.plan.hasOwnProperty("isEtisalatUAE") && this.plan.isEtisalatUAE) ||
            (this.plan.hasOwnProperty("isZainKsa") && this.plan.isZainKsa)
        ) {
            this.showPaymentLabels = true;
            this.setupOperatorGateway(this.plan);
        }

        //checking for change of language in local storage value
        eventBus.$on("check-local-storage", (data) => {
            this.localDisplayLang = data;
        });

        eventBus.$on("free-subscription-response", (response) => {
            if (this.$route.fullPath === "/viewAllPlans") {
                this.$router.push('/');
            }
            setTimeout(() => {
                if (this.showPaymentLabels) {
                    eventBus.$emit("toggleSuccessMessagePopupTrigger", {
                        response,
                        paymentDetail: this.plan
                    });
                }
            }, 500);
        });

    },
    mounted() {
        this.planCardTxt = this.appConfig.plantextoffer[this.localDisplayLang]
        this.offerPlanIds = this.appConfig.offerPlanIds;
        this.rowPlanIds = this.appConfig.rowPlanIds;
        this.planInterval = this.enumDataset[this.plan.planinterval]
      
    },
    methods: {
        ...mapActions(["paymentInitiation"]),
        currencyCode (plan) {
            return  plan.currency == "INR" ? "<span>&#8377;</span>" + " " + this.planOriginalPrice : "<span>&#36;</span>" + " " + this.planOriginalPrice;
        },
        currencyCodeSubscriber (plan) {
            return  plan.currency == "INR" ? "<span>&#8377;</span>" + " " + (plan.amount / 100) : "<span>&#36;</span>" + " " + (plan.amount / 100);
        },
        getFormatedDate(d) {
            try {
                let newDate = new Date(d);
                return newDate.getDate() + " " + newDate.toLocaleString('default', {
                    month: 'short'
                }) + " " + newDate.getFullYear();
            } catch (e) {
                return d;
            }

        },
        getPlanMapFromConfig(plan) {
            if (plan && this.planAndDiscount && plan.planid === this.planAndDiscount.planid) {
                return true
            }
        },

        openPaymentModal(plan) {
 
            if (this.$route.name == "seeAllPlans") {
                eventBus.$emit("open-rzp-modal", plan);
            } else {
                if (this.subscriberid) {
                    eventBus.$emit("open-home-rzp-modal", plan);
                 } else {
                    window.selectedPlanInfomationRouting = plan;
                    eventBus.$emit("toggle-auth");
                }
            }
          
        },

        cardLogoForOperator(plan) {
            let STCLogo = require("@/assets/icons/STC.svg");
            let OreedoLogo = require("@/assets/icons/ooredoo-logo.svg");
            let DUUAELogo = require("@/assets/icons/du_uae.png");
            let zainLogo = require("@/assets/icons/zain_logo.svg");
            let etisalatUAE = require("@/assets/icons/etisalat-logo-new.png");

            console.log("THE PLAN LOGO -- ", plan);

            if (plan.isSTC) {
                return STCLogo;
            } else if (plan.isOreedo) {
                return OreedoLogo;
            } else if (plan.isDUUAE) {
                return DUUAELogo;
            } else if (plan.isZainKwt || plan.isZainJod || plan.isZainKsa) {
                return zainLogo;
            } else if (plan.isEtisalatUAE) {
                return etisalatUAE;
            }
        },

        setupOperatorGateway(plan) {
            let operatorGateways = this.gatewayList.filter((el) => {
                return el.gwmode == "OPERATOR";
            });

            let index = operatorGateways.findIndex((element) => {
                return element.ApplicaplePlanList.length > 0 && element.ApplicaplePlanList.includes(plan.planid);
            });

            if (index > -1) {
                this.operatorGateway = operatorGateways[index];
            }
        },

        planSubscribe(item) {
            if (!this.sessionToken) {
                const newItem = {
                    ...item,
                }

                if (item && this.planAndDiscount && item.planid === this.planAndDiscount.planid) {
                    newItem.coupon = this.planAndDiscount.coupon;
                }

                this.$emit("subscribe", newItem);
                return;
            }
            if ((item.hasOwnProperty("isSTC") && item.isSTC) ||
                (item.hasOwnProperty("isOreedo") && item.isOreedo) ||
                (item.hasOwnProperty("isDUUAE") && item.isDUUAE) ||
                (item.hasOwnProperty("isZainKwt") && item.isZainKwt) ||
                (item.hasOwnProperty("isZainJod") && item.isZainJod) ||
                (item.hasOwnProperty("isEtisalatUAE") && item.isEtisalatUAE) ||
                (item.hasOwnProperty("isZainKsa") && item.isZainKsa)
            ) {
                this.subscriptionPrecheck(item);
            } else {
                this.initiatePayment(item);
            }
        },

        precheckResponse(plan) {
            let payload = {
                transactionpurpose: "SUBSCRIPTION",
                planid: plan.planid,
            };
            this.showLoader = true;
            return store.dispatch("subscriptionPrecheck", payload);
        },

        subscriptionPrecheck(plan) {
            this.precheckResponse(plan)
                .then((response) => {
                    if (response.reason) {
                        this.showLoader = true;
                        this.snackbarMessage(response.reason);
                    } else {

                        console.log("THE PRECHECK TELCO --> ", response, plan);

                        if (response.data && response.data.finalamount === 0) {
                            let payload = {
                                transactionpurpose: "SUBSCRIPTION",
                                planid: response.data.planid,
                                availabilityid: plan.availabilityset[0],
                            };

                            eventBus.$emit("create-free-subscription", payload);

                            return;
                        }

                        if (
                            (plan.hasOwnProperty("isSTC") && plan.isSTC) ||
                            (plan.hasOwnProperty("isZainKwt") && plan.isZainKwt) ||
                            (plan.hasOwnProperty("isZainJod") && plan.isZainJod)
                        ) {
                            this.initiatePayment(plan);
                        } else {
                            //check for mobile number is there in subscriber details or not.
                            let subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails")).data;
                            // subscriberDetails.mobileno = "+96895018020";
                            if (subscriberDetails) {
                                if (!subscriberDetails.mobileno) {
                                    this.showLoader = false;
                                    let payload = {
                                        isMobileno: false,
                                        planInfo: plan,
                                        gatewayInfo: this.operatorGateway,
                                        state: true,
                                    };
                                    this.$emit("close", payload);
                                } else {
                                    this.mobileNumber = subscriberDetails.mobileno;
                                    this.initiatePayment(plan);
                                }
                            }
                        }
                    }
                })
                .catch((error) => console.log(error));
        },

        constructInitParams(item) {
            let payload = {
                amount: item.amount,
                currency: item.currency,
                devicetype: "WEB",
                transactionpurpose: "SUBSCRIPTION",
                transactionmode: "CC",
                availabilityid: item.availabilityset[0],
                planid: item.planid,
            };

            if (
                this.mobileNumber &&
                ((item.hasOwnProperty("isOreedo") && item.isOreedo) ||
                    (item.hasOwnProperty("isDUUAE") && item.isDUUAE) ||
                    (item.hasOwnProperty("isEtisalatUAE") && item.isEtisalatUAE) ||
                    (item.hasOwnProperty("isZainKsa") && item.isZainKsa))
            ) {
                payload.mobileno = this.mobileNumber;
            }

            return payload;
        },

        initiatePayment(item) {
            if (
                (item.hasOwnProperty("isSTC") && item.isSTC) ||
                (item.hasOwnProperty("isZainKwt") && item.isZainKwt) ||
                (item.hasOwnProperty("isZainJod") && item.isZainJod)
            ) {
                let currentDomain = window.location.origin;

                let payload = {
                    gatewayid: this.operatorGateway.gatewayid,
                    params: this.constructInitParams(item),
                };

                if (
                    (item.hasOwnProperty("isZainKwt") && item.isZainKwt) ||
                    (item.hasOwnProperty("isZainJod") && item.isZainJod)
                ) {
                    payload.params.client_return_url = currentDomain + "/profile" + "?" + "planid=" + item.planid;
                } else {
                    payload.params.return_url = currentDomain + "/profile" + "?" + "planid=" + item.planid;
                }

                this.paymentInitiation(payload)
                    .then((response) => {
                        console.log("payment init response from plan card for TELCO", response);
                        this.showLoader = false;
                        if (response.data.reason) {
                            this.snackbarMessage(response.data.reason);
                        } else {
                            console.log("THIS IS THE TELCO PAYLOAD", item);

                            const browser = this.getPlatformType();

                            const theSource = localStorage.getItem("payment_source");

                            let paymentDetailsForGA = {
                                free_trial: item.trialperioddays,
                                payment_mode: response.data.referencedata.gwprovider,
                                plan_id: item.planid,
                                plan_name: item.planname,
                                payment_id: response.data.referencedata.paymentid,
                                source: theSource,
                                object_id: "",
                                object_name: "",
                                platform: this.getPlatform(),
                                platform_type: browser.browser,
                            };

                            // Google analytics Event
                            this.subscriptionStarted(paymentDetailsForGA);

                            // Facebook Analytics Event
                            this.faceBookInitateCheckoutEvent(paymentDetailsForGA);

                            let redirectURL = "";
                            if (response.data.referencedata.paymenturl) {
                                redirectURL =
                                    response.data.referencedata.paymenturl + `?&lang=${this.localDisplayLang === "ara" ? "ar" : "en"}`;
                            } else if (response.data.referencedata.checkoutUrl) {
                                redirectURL = response.data.referencedata.checkoutUrl;
                            }
                            window.open(redirectURL, "_self");
                        }
                    })
                    .catch((error) => console.log("error", error));
            } else if (
                (item.hasOwnProperty("isOreedo") && item.isOreedo) ||
                (item.hasOwnProperty("isDUUAE") && item.isDUUAE) ||
                (item.hasOwnProperty("isEtisalatUAE") && item.isEtisalatUAE) ||
                (item.hasOwnProperty("isZainKsa") && item.isZainKsa)
            ) {
                let payload = {
                    gatewayid: this.operatorGateway.gatewayid,
                    params: this.constructInitParams(item),
                };

                this.paymentInitiation(payload)
                    .then((response) => {
                        this.showLoader = false;
                        if (response.data.reason) {
                            this.snackbarMessage(response.data.reason);
                        } else {
                            let payload = {
                                paymentInfo: response,
                                planInfo: item,
                                isMobileno: true,
                                gatewayInfo: this.operatorGateway,
                                state: true,
                            };
                            this.$emit("close", payload);
                        }
                    })
                    .catch((error) => console.log("error", error));
            } else {
                const newItem = {
                    ...item,
                }

                if (item && this.planAndDiscount && item.planid === this.planAndDiscount.planid) {
                    newItem.coupon = this.planAndDiscount.coupon;
                }

                this.$emit("subscribe", newItem);
            }
        },
        snackbarMessage(message) {
            // snackbar.show({
            //   text: message,
            //   textColor: "#ffffff",
            //   pos: "bottom-center",
            //   actionTextColor: "#de2600",
            // });

            showSnackBar(this.$t(message),this.$t("DISMISS"));
        },
        planCancel(item) {
            console.log("THE PLAN CANCEL ITEM", item);

            this.$emit("cancel", item);
        },
    },
    beforeDestroy() {
        eventBus.$off("free-subscription-response");
    },
    mixins: [Utility, googleAnalytics, facebookPixelAnalytics],
};
</script>
  
  
<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./planCard.scss"
</style>
